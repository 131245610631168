<template>
  <form class="form">
    <fieldset class="form__fieldset">
      <div class="row">
        <div class="col-3">
          <FveFileImageCropperPreview
            label=""
            name="photo"
            v-model="form.photo"
          />
        </div>
        <div class="col-9">
          <FveText
            label="Заголовок акции"
            name="name"
            required
            v-model="form.name"
          />
          <FveTextarea
            label="Информация"
            name="about"
            required
            v-model="form.about"
          />
          <p class="date-section-title">Даты проведения</p>
          <div class="row">
            <div class="col-5">
              <FveDateTimePicker
                label="От"
                name="time"
                v-model="form.active_from"
              />
            </div>
            <div class="col-5">
              <FveDateTimePicker
                label="До"
                name="time"
                v-model="form.active_to"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <FveDateTimePicker
                label="Дата публикации"
                name="time"
                v-model="form.published_at"
              />
            </div>
          </div>
          <FveCheckbox
            label="Отправить уведомления пользователям"
            v-model="form.notify"
          />
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>

import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveTextarea from "@widgetFormValidate/src/Element/Text/FveTextarea";
import FveFileImageCropperPreview from "@widgetFormValidate/src/Element/File/FveFileImageCropperPreview";
import FveDateTimePicker from "@widgetFormValidate/src/Element/Date/FveDateTimePicker";
import FveCheckbox from "@widgetFormValidate/src/Element/Checkbox/FveCheckbox";
//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";

export default {
  mixins: [
    FveFormMixin
  ],
  components: {
    FveText,
    FveTextarea,
    FveFileImageCropperPreview,
    FveDateTimePicker,
    FveCheckbox
  },
  methods: {
    formSchema() {
      return {
        photo        : { type: FileClass, default: () => { return null; } },
        name         : { type: String, default: () => { return ''; } },
        about        : { type: String, default: () => { return ''; } },
        published_at : { type: String, default: () => { return (new DateTime()).format('YYYY-MM-DDTHH:mm:ss'); } },
        active_from  : { type: String, default: () => { return (new DateTime()).format('YYYY-MM-DDTHH:mm:ss'); } },
        active_to    : { type: String, default: () => { return (new DateTime()).format('YYYY-MM-DDTHH:mm:ss'); } },
        notify       : { type: String, default: () => { return true; } },
      };
    },
  },
};

</script>

<style lang="scss" scoped>
  .date-section-title {
    font-size: 17px;
    margin-bottom: 8px;
  }
</style>
