<template>
  <ClubTemplate :clubId="clubId">
    <div class="container">
      <div class="page-header">
        <div class="page-header__ell">
          <h1 class="page-title">Редактировать акцию</h1>
        </div>
      </div>
      <ClubPromotionAddForm :formData="promotion" ref="ClubPromotionAddForm" />
      <form class="form">
        <div class="btn-gp">
          <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
          <router-link :to="{name: this.$routeName.CLUB_PROMOTION_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
        </div>
      </form>
    </div>
  </ClubTemplate>
</template>

<script>
import ClubTemplate from './_component/ClubTemplate.vue';
import ClubPromotionAddForm from "@component/Form/ClubPromotionAddForm";

export default {
  components: {
    ClubTemplate,
    ClubPromotionAddForm
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    },
    promotionId: [String, Number]
  },
  data() {
    return {
      promotion: {},
    };
  },
  methods: {
    async save() {
      let formData = this.$refs.ClubPromotionAddForm.formSubmitGetData();
      if(!formData) { return; }
      let sendData = Object.assign({}, formData);
      
      RequestManager.Club.updateClubPromotion({ clubId: this.clubId, promotionId: this.promotionId, promotion: sendData }).then((res) => {
        this.$toasts.push({
          message: 'Акция успешно обновлена',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.CLUB_PROMOTION_LIST });
      });
    }
  },
  mounted() {
    RequestManager.Club.getClubPromotionById({
      clubId        : this.clubId,
      promotionId   : this.promotionId,
    }).then((promotion) => {
      this.promotion = Object.assign({}, promotion);
    });
  }
};
</script>
